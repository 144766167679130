import React from 'react';

// Own components
import SEO from '../components/seo';
import Index from '../containers/Index';
import Layout from '../components/Layout/es';

const IndexPage = () => (
  <Layout>
    <SEO title="Bienvenidos" />    
    <Index />
  </Layout>
);

export default IndexPage;
