// The service id must be unique in the array
export const services = [
    {
      id: 'technicalAdvice',
      coverImage: '/images/services/technical-advise-cover.jpeg',
      image: '/images/services/technical-advise.jpeg',
      icon: 'cncicon',
      title: 'technicalAdvice',
      description: 'technicalAdviceDescription',
      shortdescription: 'technicalAdviceShortDescription',
      features: [],
    },
    {
      id: 'epoxicCovering',
      coverImage: '/images/services/epoxic-covering-cover.jpeg',
      image: '/images/services/epoxic-covering.jpeg',
      icon: 'chemicalicon',
      title: 'epoxicCovering',
      description: 'epoxicCoveringDescription',
      shortdescription: 'epoxicCoveringShortDescription',
      features: [],
    },
    {
      id: 'trainingSeminars',
      coverImage: '/images/services/training-seminars-cover.jpeg',
      image: '/images/services/training-seminars.jpeg',
      icon: 'cncicon',
      title: 'trainingSeminars',
      description: 'trainingSeminarsDescription',
      shortdescription: 'trainingSeminarsShortDescription',
      features: [],
    },
    {
      id: 'sodaBlasting',
      coverImage: '/images/services/soda-blasting-cover.jpeg',
      image: '/images/services/soda-blasting.jpeg',
      icon: 'chemicalicon',
      title: 'sodaBlasting',
      description: 'sodaBlastingDescription',
      shortdescription: 'sodaBlastingShortDescription',
      features: [
        {
          id: 'feature1',
          title: 'efficiendyAndSustainability',
          description: 'efficiendyAndSustainabilityDescription',
        },
        {
          id: 'feature2',
          title: 'responsibleEnvironment',
          description: 'responsibleEnvironmentDescription',
        },
        {
          id: 'feature3',
          title: 'safeProcess',
          description: 'safeProcessDescription',
        },
        {
          id: 'feature4',
          title: 'powerBalance',
          description: 'powerBalanceDescription',
        },
      ],
    },
  ];