// The slide id must be unique in the array
export const slider = [
  {
    id: 'slide-1',
    alignContent: 'home1-slide3',
    titleId: 'titleSlide1',
    subtitlleId: 'subtitleSlide1',
    button1: {
      textId: 'button1Slide1',
      url: 'about',
      class: 'btn-center',
    },
    button2: {
      textId: 'button2Slide1',
      url: '#var_testimonial',
      class: 'btn-center',
    },
  },
  {
    id: 'slide-2',
    alignContent: 'home1-slide3',
    titleId: 'titleSlide2',
    subtitlleId: 'subtitleSlide2',
    button1: {
      textId: 'button1Slide2',
      url: '#products',
      class: 'btn-center',
    },
    button2: {
      textId: 'button2Slide2',
      url: 'products/?family=adhesivesAndIndustrialSealants',
      class: 'btn-center',
    },
  },
  {
    id: 'slide-3',
    alignContent: 'home1-slide3',
    titleId: 'titleSlide3',
    subtitlleId: 'subtitleSlide3',
    button1: {
      textId: 'button1Slide3',
      url: '#services',
      class: 'btn-center',
    },
    button2: {
      textId: 'button2Slide3',
      url: 'services',
      class: 'btn-center',
    },
  },
  {
    id: 'slide-4',
    alignContent: 'home1-slide3',
    titleId: 'titleSlide4',
    subtitlleId: 'subtitleSlide4',
    button1: {
      textId: 'button1Slide4',
      url: 'contact',
      class: 'btn-center',
    },
    button2: {
      textId: 'button2Slide4',
      url: '#social-network',
      class: 'btn-center',
    },
  },
  {
    id: 'slide-5',
    alignContent: 'home1-slide3',
    titleId: 'titleSlide5',
    button1: {
      textId: 'button1Slide5',
      url: '#statistics',
      class: 'btn-center',
    },
  },
  {
    id: 'slide-6',
    alignContent: 'home1-slide3',
    titleId: 'titleSlide6',
    button1: {
      textId: 'button1Slide6',
      url: 'products/?family=advancedPolymerCoatings',
      class: 'btn-center',
    },
  },
  {
    id: 'slide-7',
    alignContent: 'home1-slide3',
    titleId: 'titleSlide7',
    button1: {
      textId: 'button1Slide7',
      url: 'multimedia',
      class: 'btn-center',
    },
  }
];

// The counter id must be unique in the array
const date = new Date();
export const counters = [
  {
    id: 'gramsAndSealantsDistributed',
    value: ((((date.getFullYear() - 2019) * (date.getMonth() + 1) * 2.8) / 100) + 1) * 3289125,
    textId: 'gramsAndSealantsDistributed',
    unit: 'grams',
  },
  {
    id: 'deliveryAndCompliance',
    value: 100,
    textId: 'deliveryAndCompliance',
    unit: '%',
  },
  {
    id: 'yearsOfExperience',
    value: date.getFullYear() - 2010,
    textId: 'yearsOfExperience',
    unit: '',
  },
  {
    id: 'customers',
    value: Math.round((date.getFullYear() - 2019) * ((date.getMonth() + 1) / 3) + 49),
    textId: 'customers',
    unit: '',
  },
];

// The product filter id must be unique in the array
export const productsFilters = [
  {
    id: 'adhesivesAndIndustrialSealants',
    textId: 'adhesivesAndIndustrialSealants',
    className: 'active',
  },
  {
    id: 'advancedPolymerCoatings',
    textId: 'advancedPolymerCoatings',
    className: '',
  },
  {
    id: 'elastomericThermalInsulations',
    textId: 'elastomericThermalInsulations',
    className: '',
  },
  {
    id: 'plasmaSurfaceTreatment',
    textId: 'plasmaSurfaceTreatment',
    className: '',
  },
];

// The product id must be unique in the array and It must be the same id at products.js
export const productFamily = {
  adhesivesAndIndustrialSealants: {
    url: 'products?family=adhesivesAndIndustrialSealants',
    images: [
      {
        id: 'adhesivesAndIndustrialSealants-img-1',
        url: '/images/index/products/kleb-lock-product.jpeg',
        showButton: true,
      },
      {
        id: 'adhesivesAndIndustrialSealants-img-2',
        url: '/images/index/products/kleb-lock-product-1.jpeg',
        showButton: false,
      },
      {
        id: 'adhesivesAndIndustrialSealants-img-3',
        url: '/images/index/products/kleb-lock-product-2.jpeg',
        showButton: false,
      },
      {
        id: 'adhesivesAndIndustrialSealants-img-4',
        url: '/images/index/products/kleb-lock-product-3.jpeg',
        showButton: false,
      },
    ]
  },
  advancedPolymerCoatings: {
    url: 'products?family=advancedPolymerCoatings',
    images: [
      {
        id: 'advancedPolymerCoatings-img-1',
        url: '/images/index/products/kleb-lock-product-polymers.jpeg',
        showButton: true,
      },
      {
        id: 'advancedPolymerCoatings-img-2',
        url: '/images/index/products/kleb-lock-product-polymers-1.jpeg',
        showButton: false,
      },
      {
        id: 'advancedPolymerCoatings-img-3',
        url: '/images/index/products/kleb-lock-product-polymers-2.jpeg',
        showButton: false,
      },
      {
        id: 'advancedPolymerCoatings-img-4',
        url: '/images/index/products/kleb-lock-product-polymers-3.jpeg',
        showButton: false,
      },
    ]
  },
  elastomericThermalInsulations: {
    url: 'products?family=elastomericThermalInsulations',
    images: [
      {
        id: 'elastomericThermalInsulations-img-1',
        url: '/images/index/products/isopipe-product.jpeg',
        showButton: true,
      },
      {
        id: 'elastomericThermalInsulations-img-2',
        url: '/images/index/products/isopipe-product-1.jpeg',
        showButton: false,
      },
      {
        id: 'elastomericThermalInsulations-img-3',
        url: '/images/index/products/isopipe-product-2.jpeg',
        showButton: false,
      },
      {
        id: 'elastomericThermalInsulations-img-4',
        url: '/images/index/products/isopipe-product-3.jpeg',
        showButton: false,
      },
    ]
  },
  plasmaSurfaceTreatment: {
    url: 'products?family=plasmaSurfaceTreatment',
    images: [
      {
        id: 'plasmaSurfaceTreatment-img-1',
        url: '/images/index/products/relyon-1.jpeg',
        showButton: true,
      },
      {
        id: 'plasmaSurfaceTreatment-img-2',
        url: '/images/index/products/relyon-2.jpeg',
        showButton: false,
      },
      {
        id: 'plasmaSurfaceTreatment-img-3',
        url: '/images/index/products/relyon-3.jpeg',
        showButton: false,
      },
      {
        id: 'plasmaSurfaceTreatment-img-4',
        url: '/images/index/products/relyon-4.jpeg',
        showButton: false,
      },
    ]
  }
};


export const testimonials = [
  {
    id: 'testimonial-1',
    title: 'testimonialTitle1',
    subtitle: 'testimonialSubtitle1',
    testimonial: 'testimonialDescription1',
    image: '/images/index/testimonials/areaCompras.jpeg',
    extraClass: 'active',
  },
  {
    id: 'testimonial-2',
    title: 'testimonialTitle2',
    subtitle: 'testimonialSubtitle2',
    testimonial: 'testimonialDescription2',
    image: '/images/index/testimonials/azucar.jpeg',
    extraClass: '',
  },
  {
    id: 'testimonial-3',
    title: 'testimonialTitle3',
    subtitle: 'testimonialSubtitle3',
    testimonial: 'testimonialDescription3',
    image: '/images/index/testimonials/mantenimiento.jpeg',
    extraClass: '',
  },
  {
    id: 'testimonial-4',
    title: 'testimonialTitle4',
    subtitle: 'testimonialSubtitle4',
    testimonial: 'testimonialDescription4',
    image: '/images/index/testimonials/ingenieria.jpeg',
    extraClass: '',
  },
  {
    id: 'testimonial-5',
    title: 'testimonialTitle5',
    subtitle: 'testimonialSubtitle5',
    testimonial: 'testimonialDescription5',
    image: '/images/index/testimonials/planeacion.jpeg',
    extraClass: '',
  },
];