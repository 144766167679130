import React, {  useEffect } from 'react';
import { useIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';

// Own components
import Icon from '../../components/Icon/icon';
import { SocialNetworks } from '../../components/SocialNetworks';

// Assets & Misc
import { useLanguages } from '../../hooks';
import { services } from '../../constants/services';
import { CustomLink } from '../../utils/navigation';
import { animateCounters } from '../../utils/animations';
import { slider, counters, productsFilters, productFamily, testimonials } from '../../constants/index';

const animateProducts = () => {
  function t(values) {
    const element = 'webkitAnimationEnd animationend';
    values.each(function() {
      const that = $(this);
      const node = t.data('animation');
      that.addClass(node).one(element, function() {
        that.removeClass(node);
      });
    });
  }

  const animateText = $('.animate_text');
  const items = animateText.find('.item:first').find("[data-animation ^= 'animated']");
  if('carousel' in animateText) {
    animateText.carousel();
  }
  t(items);
  animateText.on('slide.bs.carousel', function(event) {
    const slides = $(event.relatedTarget).find("[data-animation ^= 'animated']");
    t(slides);
  });

  const tooltip = $('[data-toggle="tooltip"]');
  if ('tooltip' in tooltip) {
    tooltip.tooltip(), $('.carousel-inner').swipe({
        swipeLeft() {
          $(this).parent().carousel('next')
        },
        swipeRight() {
          $(this).parent().carousel('prev')
        },
        threshold: 0
    });
  }
  $('.two_shows_one_move .item').each(function() {
      for (let t = $(this), e = 1; e < 2; e++) t = t.next(), t.length || (t = $(this).siblings(':first')), t.children(':first-child').clone().addClass(`cloneditem-${  e}`).appendTo($(this))
  });
  $('.three_shows_one_move .item').each(function() {
      for (let t = $(this), e = 1; e < 3; e++) t = t.next(), t.length || (t = $(this).siblings(':first')), t.children(':first-child').clone().addClass(`cloneditem-${  e}`).appendTo($(this))
  });
  $('.four_shows_one_move .item').each(function() {
      for (let t = $(this), e = 1; e < 4; e++) t = t.next(), t.length || (t = $(this).siblings(':first')), t.children(':first-child').clone().addClass(`cloneditem-${  e}`).appendTo($(this))
  });
  $('.four_shows_one_move_big .item').each(function() {
      for (let t = $(this), e = 1; e < 4; e++) t = t.next(), t.length || (t = $(this).siblings(':first')), t.children(':first-child').clone().addClass(`cloneditem-${  e}`).appendTo($(this))
  });
  $('.five_shows_one_move .item').each(function() {
      for (let t = $(this), e = 1; e < 5; e++) t = t.next(), t.length || (t = $(this).siblings(':first')), t.children(':first-child').clone().addClass(`cloneditem-${  e}`).appendTo($(this))
  });
  $('.six_shows_one_move .item').each(function() {
      for (let t = $(this), e = 1; e < 6; e++) t = t.next(), t.length || (t = $(this).siblings(':first')), t.children(':first-child').clone().addClass(`cloneditem-${  e}`).appendTo($(this))
  })
}

function animateCarousel(id) {
  //Function to animate slider captions 
  function doAnimations(elems) {
      //Cache the animationend event in a variable
      var animEndEv = 'webkitAnimationEnd animationend';

      elems.each(function() {
          var $this = $(this),
              $animationType = $this.data('animation');
          $this.addClass($animationType).one(animEndEv, function() {
              $this.removeClass($animationType);
          });
      });
  }

  //Variables on page load 
  var $myCarousel = $(id),
      $firstAnimatingElems = $myCarousel.find('.item:first').find("[data-animation ^= 'animated']");

  //Initialize carousel 
  $myCarousel.carousel({
      interval: 7000,
      ride: true,
      ride: 'carousel'
  });

  //Animate captions in first slide on page load 
  doAnimations($firstAnimatingElems);

  //Other slides to be animated on carousel slide event 
  $myCarousel.on('slide.bs.carousel', function(e) {
      var $animatingElems = $(e.relatedTarget).find("[data-animation ^= 'animated']");
      doAnimations($animatingElems);
  });
}

const Index = () => {
  const { formatMessage } = useIntl();
  const { path } = useLanguages();

  useEffect(() => {
    animateCounters();
    animateProducts();
    animateCarousel('#minimal-bootstrap-carousel');
    animateCarousel('#var_testimonial');
  }, []);

  return (
    <>
      {/* Slider */}
      <div id="minimal-bootstrap-carousel" className="home3-banner carousel slide carousel-fade shop-slider full_width" data-ride="carousel">
         <div className="carousel-inner" aria-roledescription="carousel">
           {slider.map(({ id, alignContent, titleId, subtitlleId, button1, button2 }, index) => (
            <div key={id} className={`item ${index === 0 ? 'active' : '' } ${id}`} aria-roledescription="slide" aria-label={formatMessage({ id: titleId })}>
              <div className="carousel-caption">
                  <div className="thm-container">
                    <div className="box valign-middle">
                        <div className={`content text-center ${alignContent}`}>
                          <h1 data-animation="animated fadeInUp">{formatMessage({ id: titleId })}</h1>
                          {subtitlleId && <p data-animation="animated fadeInDown">{formatMessage({ id: subtitlleId })}</p>}
                          <CustomLink to={`${path}${button1.url}`} data-animation="animated fadeInDown" className={`header-requestbtn learn-more hvr-bounce-to-right ${button1.class}`}>
                            {formatMessage({ id: button1.textId })}
                          </CustomLink>
                          {button2 && (
                            <CustomLink to={`${path}${button2.url}`} data-animation="animated fadeInDown" className={`header-requestbtn learn-more our-solution hvr-bounce-to-right ${button2.class}`}>
                              {formatMessage({ id: button2.textId })}
                            </CustomLink>
                          )}
                        </div>
                    </div>
                  </div>
              </div>
            </div>
           ))}
        </div>
        <a className="left carousel-control" href="#minimal-bootstrap-carousel" role="button" data-slide="prev" aria-label="Previous">
           <i className="fa fa-angle-left" />
           <span className="sr-only">Previous</span>
        </a>
        <a className="right carousel-control" href="#minimal-bootstrap-carousel" role="button" data-slide="next" aria-label="Next">
          <i className="fa fa-angle-right" />
          <span className="sr-only">Next</span>
        </a>
      </div>

      {/* About us */}
      <section className="pad95-100-top-bottom faq_04">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <h3 className="marbtm30"><FormattedMessage id="aboutUs" values={{ prefix: '' }}/></h3>
              <span className="image_hover wdt-100 img marbtm30">
              <img data-src="/images/index/about-us-index.jpeg" className="img-responsive zoom_img_effect lazyload" alt="who-are" />
              </span>
              <p className="fnt-17 text-justify"><FormattedHTMLMessage id="indexAboutUsdescription" /></p>
              <CustomLink to={`${path}about`} data-animation="animated fadeInUp" className="header-requestbtn more-infobtn hvr-bounce-to-right">
                <FormattedMessage id="moreInfo" />
              </CustomLink>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12 faq-mobile-margin">
              <div className="accordion-first accordion-second clearfix acord_mar_non">
                  <div className="accordion" id="accordion2">
                    <div className="accordion-group">
                        <div className="accordion-heading">
                          <a className="accordion-toggle" data-toggle="collapse" data-parent="#accordion2" href="#mission">
                            <em className="icon-fixed-width fa fa-plus" />
                            <FormattedMessage id="mission" />
                          </a>
                        </div>
                        <div id="mission" className="accordion-body collapse">
                          <div className="accordion-inner text-justify">
                              <p><FormattedMessage id="missionDetail" /></p>
                          </div>
                        </div>
                    </div>
                    <div className="accordion-group">
                        <div className="accordion-heading">
                          <a className="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion2" href="#vision">
                            <em className="icon-fixed-width fa fa-plus" />
                            <FormattedMessage id="vision" />
                          </a>
                        </div>
                        <div id="vision" className="accordion-body collapse">
                          <div className="accordion-inner text-justify">
                              <FormattedHTMLMessage id="visionDetail" />
                          </div>
                        </div>
                    </div>
                    <div className="accordion-group">
                        <div className="accordion-heading">
                          <a className="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion2" href="#corporateValues">
                            <em className="icon-fixed-width fa fa-plus" />
                            <FormattedMessage id="corporateValues" />
                          </a>
                        </div>
                        <div id="corporateValues" className="accordion-body collapse">
                          <div className="accordion-inner text-justify">
                            <FormattedHTMLMessage id="corporateValuesDetail" />
                          </div>
                        </div>
                    </div>
                    <div className="accordion-group">
                        <div className="accordion-heading">
                          <a className="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion2" href="#strategicApproach">
                            <em className="icon-fixed-width fa fa-plus" />
                            <FormattedMessage id="strategicApproach" />
                          </a>
                        </div>
                        <div id="strategicApproach" className="accordion-body collapse">
                          <div className="accordion-inner text-justify">
                            <FormattedHTMLMessage id="strategicApproachDetail" />
                          </div>
                        </div>
                    </div>
                    <div className="accordion-group">
                        <div className="accordion-heading">
                          <a className="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion2" href="#valueProposal">
                            <em className="icon-fixed-width fa fa-plus" />
                            <FormattedMessage id="valueProposal" />
                          </a>
                        </div>
                        <div id="valueProposal" className="accordion-body collapse">
                          <div className="accordion-inner text-justify">
                            <FormattedHTMLMessage id="valueProposalDetail" />
                          </div>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Products */}
      <section id="products" className="home4 recent-project-section pad100-top  projectsec1">
        <div className="container">
          <h3 className="white-color text-center">{formatMessage({ id: 'ourProducts' })}</h3>
        </div>
        <ul className="nav nav-tabs" role="tablist">
          {productsFilters.map((productFilter) => (
            <li className={productFilter.className} key={productFilter.id} role="presentation">
              <a href={`#${productFilter.id}`} aria-controls={productFilter.id} role="tab" data-toggle="tab"><strong>{formatMessage({ id: productFilter.textId })}</strong></a>
            </li>
          ))}
        </ul>
        <div className="tab-content">
            {productsFilters.map(({ id: section, className }, index) => (
              <div key={`tabpanel-${section}`} role="tabpanel" className={`tab-pane ${className}`} id={section}>
                <div className="full_wrapper carousel slide four_shows_one_move home1-project" id={`our_project${index + 1}`} data-ride="carousel">
                  <div className="controls">
                    <a className="left fa fa-angle-left next_prve_control" href={`#our_project${index + 1}`} data-slide="prev" aria-label="Previous" />
                    <a className="right fa fa-angle-right next_prve_control" href={`#our_project${index + 1}`} data-slide="next" aria-label="Next" />
                  </div>
                  <div className="carousel-inner">
                    {productFamily[section].images.map(({ id: imageId, url: imageUrl, showButton }, indexProduct) => (
                      <div key={imageId} className={`item ${indexProduct === 0 ? 'active' : ''}`}>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 img pad_zero">
                          <div className="grid">
                            <div className="image-zoom-on-hover">
                              <div className="gal-item">
                                <CustomLink className="black-hover" to={`${path}${productFamily[section].url}`}>
                                  <img className="img-full img-responsive lazyload" data-src={imageUrl} alt={imageUrl} />
                                  <div className="tour-layer delay-1" />
                                  <div className="vertical-align">
                                    <div className="border">
                                    <FormattedMessage id={section} tagName="h5" />
                                    </div>
                                    {showButton && (
                                      <div className="view-all hvr-bounce-to-right slide_learn_btn view_project_btn">
                                        <FormattedMessage id="viewProduct" />
                                      </div>
                                    )}
                                  </div>
                                </CustomLink>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
         </div>
      </section>
      {/* Services */}
      <section id="services" className="pad95-45-top-bottom">
        <div className="container">
          <h3 className="text-center">{formatMessage({ id: 'ourServices' })}</h3>
          <div className="row">
            {services.map(({ id: serviceId, coverImage: serviceImage, title: serviceTextId, shortdescription }) => (
              <div key={serviceId} className="col-lg-4 col-md-4 col-sm-6 col-xs-12 home3-service-column marbtm50">
                <CustomLink to={`${path}service?id=${serviceId}`}>
                  <span className="image_hover img">
                    <img data-src={serviceImage} className="img-responsive zoom_img_effect lazyload" alt={serviceId} />
                  </span>
                </CustomLink>
                <h4 style={{ height: 80 }}>
                  <FormattedMessage id={serviceTextId} />
                </h4>
                <p className="line-height26 marbtm20 text-justify">
                  <FormattedMessage id={shortdescription} />
                </p>
                <span className="read-more-link">
                  <CustomLink to={`${path}service?id=${serviceId}`}>
                    {formatMessage({ id: 'readMore' })}
                  </CustomLink>
                </span>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* Counter */}
      <section id="statistics" className="home2 home4 bestthing-section text-center">
        <div className="container">
          <div className="bestthing-text-column home4-bestthing-txt">
            <h2><FormattedMessage id="someImportantData" tagName="strong" /></h2>
            <p className="fnt-17 text-justify">
              <FormattedMessage id="someImportantDataDescription" />
            </p>
          </div>
          <div className="static-section home3-static home4-static-section">
            <ul>
              {counters.map(({ id: counterId, value: counterValue, textId: counterText, unit }) => (
                <li key={counterId}>
                  <h2>{counterValue}</h2>
                  <p style={{ paddingRight: '1rem', paddingLeft: '1rem' }}>
                    <FormattedMessage id={counterText} values={{ unit: unit ? `(${formatMessage({ id: unit })})` : '' }} />
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
      {/* Testimonials */}
      <section className="pad95-100-top-bottom home3_testimonial carousel slide two_shows_one_move common_testimonial_01" id="var_testimonial" data-ride="carousel">
         <div className="container">
            <h3 className=" text-center">
              <FormattedMessage id="testimonials" />
            </h3>
            <div className="text-center">
               <div className="controls">
                  <a className="left next_prve_control icon-padding" href="#var_testimonial" data-slide="prev" aria-label="Previous">
                    <Icon icon="angle-left" />
                  </a>
                  <a className="right next_prve_control icon-padding" href="#var_testimonial" data-slide="next" aria-label="Next">
                    <Icon icon="angle-right" />
                  </a>
                </div>
            </div>
            <div className="row">
               <div className="carousel-inner" aria-roledescription="carousel">
                {testimonials.map(testimonial => (
                  <div id={testimonial.id} key={testimonial.id} className={`item ${testimonial.extraClass}`} aria-roledescription="slide" aria-labelledby={testimonial.id} aria-label={formatMessage({ id: testimonial.title})}>
                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 client-column">
                        <span className="home3-client-img"><img data-src={testimonial.image} alt="Testimonial" className="img-circle lazyload testimonial-img" /></span>
                        <div className="home3-client-desc">
                          <FormattedMessage id={testimonial.title} tagName="h4" />
                          <span className="client-designation">{<FormattedMessage id={testimonial.subtitle} />}</span>
                          <p className="text-justify"><FormattedMessage id={testimonial.testimonial} /></p>
                        </div>
                     </div>
                  </div>
                ))}
               </div>
            </div>
         </div>
      </section>
      {/* Social networks */}
      <SocialNetworks />
    </>
  );
};

export default Index;